import {Affix, FloatButton} from 'antd';
import {useEffect, useState} from 'react';
import '../ContentIndex.css';
import logoImg from '../../images/logo2.png';
import img1 from '../../images/xm/lixue.jpg';
import img2 from '../../images/xm/lixue2.jpg';
import yemei from '../../images/防火检测详情页图.jpg';
import xia from '../../images/xq/logo至于每一个介绍最下方.jpg';
import logo22 from '../../images/logo22.png';
import weixing from '../../images/weixing.jpg';
const App = () => {
    useEffect(() => {
        // 配置swiper播放 配置项都写这里面
        // console.log(1232112312)
        // const mySwiper = new Swiper('.swiper', {
        //     autoplay: true,
        //     loop: true
        // })
        window.scrollTo(0, 0);

    }, [])
    const [scrollPosition, setScrollPosition] = useState(0);
    const [top, setTop] = useState(118);
    const scroll=(y)=>{
        window.scrollTo(0, y);
    }

    function createMarkup() {
        return {
            __html: `
            <div class="nyrcon">
                <div class="nyrt"><h1 class="contact-tit">防火检测</h1></div>
                <div class="cc-pp">
                    <h2><span style="font-size: 16px; color: rgb(0, 0, 0);"><strong>防火检测介绍</strong></span></h2><p>
                    <span style="font-size: 16px; color: rgb(0, 0, 0);"></span></p><p><a
                    style="color: rgb(0, 0, 0); text-decoration: underline;"><span
                    style="color: rgb(0, 0, 0);">防火检测</span></a><span style="color: rgb(0, 0, 0);">民用飞机机载设备环境条件和试验方法 第14部分:防火、可燃性试验 HB 6167.14-2014
民用飞机软管和管组件防火试验要求 HB 7044-2014 </span></p><p
                    style="text-align: center;"></p><h2><span
                    style="font-size: 16px; color: rgb(0, 0, 0);"><strong>航空管路耐火试验</strong></span></h2>
                    <p><span
                    style="color: rgb(0, 0, 0);">航空管路耐火试验的目的是检测航空管路在受到火焰作用时的耐火性能，以评估其在火灾环境下的安全性和可靠性，验证组件满足CCAR23.1183、CCAR25.1183、CCAR27.1183、CCAR29.1183以及组件专用规范规定的耐火要求</span>
                </p><p><span style="color: rgb(0, 0, 0);"><strong>1.试验相关标准</strong></span></p><p><span
                    style="color: rgb(0, 0, 0);">(1) HB 7044-2014民用飞机软管和管组件防火试验要求
(2) GJB 2837A-2017聚乙烯软管组件规范<br />
其中，GJB 2837A-2017聚乙烯软管组件规范中引用的标准为组件耐火试验按照HB 7044-2014。</span>
                </p><p><span style="color: rgb(0, 0, 0);"><strong>2.检测组件对象</strong></span></p><p><span
                    style="color: rgb(0, 0, 0);">a) 类型Ia,橡胶软管组件，用手燃油和滑油系统；<br />

b) 类型Ib,橡胶软管组件，用于液压系统；<br />

c) 类型IIa，聚四氟乙烯软管组件，用于燃油和滑油系统；<br />

d）类型IIb聚四氟乙烯软管组件，用于液压系统；<br />

e) 类型Ⅲa,硬管组件，用于燃油和滑油系统；<br />

f) 类型Ⅲb,硬管组件，用于液压系统。</span>
                </p><p><span style="color: rgb(0, 0, 0);"><strong>3.耐火级别</strong></span></p><p><span
                    style="color: rgb(0, 0, 0);">a) A级，耐火时间5min;<br />

b) B级，耐火时间15min。</span>
                </p><h2><span style="font-size: 16px; color: rgb(0, 0, 0);"><strong>4.试验条件</strong></span>
                </h2><p><span
                    style="color: rgb(0, 0, 0);">①　火焰温度及大小：火焰温度1090℃±80℃，火焰中心高度应不小于178mm；<br />

②　火焰强度：火焰热容量应为1329+60-20W；<br />

③　气流速度和环境温度：气流速度2m/s,且应有排风装置，使空气温度5℃～38℃；<br />

④　振动：经受火焰一端应经受振动作用，振动可沿横向或纵向，振幅±1.59mm，频率33Hz；<br />

⑤　工作油液要求：采用SAE No.20润滑油或工作用油液，温度93℃～110℃，油液压力为工作压力或系统工作压力；<br />

⑥　油液流量：<br />

燃油及润滑系统a型组件流量Q（L/min）=0.03×管内径2；<br />

液压系统b型组件流量（L/min）Q=0.006×管内径2,管内径尺寸单位为毫米（mm）<br />

⑦　试件要求：检验合格组件中任意选长度≥600mm试件，可在外部套上防火套管；<br />

⑧　试验设备要求：需满足1、2参数要求。</span></p>

                </div>
            </div>`
        };
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">
                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>

            <div style={{position:'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{position:"absolute",top:'35%',left:'50px',fontSize:50,color:'#fff',fontWeight:"bold",width:1000,textAlign:"center"}}>
                    <span>航空管路耐火试验</span><br />
                    {/*<span>上门服务 保证一次性通过</span><br />*/}
                    {/*<span style={{fontWeight:100,fontSize:31}}>准则宣贯、文件编制、内部审核、管理评审、质量记录、技术记录。</span>*/}
                </div>
            </div>
            {/*<img src={yemei}/>*/}<div className={'service-details'} >
            <Affix offsetTop={top}>
                <div className="l-box" >
                    <div className="s-plate">
                        <div className="d-nav">
                            <ul>
                                <li onClick={event => scroll(30)}>
												<span>
													<i></i>防火检测介绍</span>
                                </li>
                                <li onClick={event => scroll(200)}>
												<span>
													<i></i>航空管路耐火试验</span>
                                </li>
                                <li onClick={event => scroll(300)}>
												<span>
													<i></i>试验相关标准</span>
                                </li>
                                <li onClick={event => scroll(400)}>
												<span>
													<i></i>检测组件对象</span>
                                </li>
                                <li onClick={event => scroll(580)}>
												<span>
													<i></i>耐火级别</span>
                                </li>
                                <li onClick={event => scroll(650)}>
												<span>
													<i></i>试验条件</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Affix>
            <div className={'r-box'}>
                <div dangerouslySetInnerHTML={createMarkup()}/>
            </div>
        </div>
            {/*<img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>*/}

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                <div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
