import { Layout, Button } from 'antd';
// import { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';    // 路由插件
import logoImg from '../images/index.jpg';
import CMA from '../images/logo.jpg';
import './index.css';
import { MessageOutlined,PhoneOutlined } from '@ant-design/icons';
const { Header, Footer, Sider, Content } = Layout;

const App = () => {
    return (
        <Layout>
            <Content>
                <div className={'index'}>
                    {/*<div className={'top'}>*/}
                    {/*    <img src={CMA} width={80}/>*/}
                    {/*</div>*/}
                    <div className={'doc'}>
                        <div className={'Fanya_three_TT'}>
                            多年专注于CMA、CNAS认证咨询服务
                        </div>
                        <div className={'Fanya_three_Desc'}>
                            涵盖检测/校准实验室认可、实验室资质认定、检验机构认可、医学实验室认可、国防实验室认可、能力验证/测量审核及专业技术培训等15个类别咨询项目
                        </div>
                        <a className={'bottom'}><Link to={'/ContentIndex'}  key={'contentIndex'}><MessageOutlined />  立即咨询</Link></a>
                        <a className={'bottom'}><PhoneOutlined />18957441030</a>
                    </div>
                    <div style={{padding:0,marginTop:0,position:"absolute",width:'100%', height:document.documentElement.clientHeight,overflow:"hidden"}}>
                        <img src={logoImg} width={'100%'}/>
                    </div>
                {/*<img style={{padding:0,margin:0,position:"absolute"}} src={logoImg} width={document.documentElement.clientWidth} height={document.documentElement.clientHeight} />*/}
                </div>
            </Content>
        </Layout>
    );
};
export default App;
