import {Layout, Button, Carousel, Tabs, FloatButton} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import Script from 'react-load-script';
import $ from 'jquery';
import axios from 'axios';
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Link} from 'react-router-dom';    // 路由插件
import './ContentIndex.css';
import logoImg from '../images/logo2.png';
// import jieshao from '../images/xq/';
import liucheng from '../images/xq/扩展流程.png';
import yemei from '../images/xq/扩项详情页眉.jpg';import xia from '../images/xq/logo至于每一个介绍最下方.png';
import logo22 from '../images/logo22.png';
import weixing from '../images/weixing.jpg';

const {Header, Footer, Sider, Content} = Layout;
const App = () => {
    useEffect(() => {
        // 配置swiper播放 配置项都写这里面
        // console.log(1232112312)
        // const mySwiper = new Swiper('.swiper', {
        //     autoplay: true,
        //     loop: true
        // })
        window.scrollTo(0, 0);
    }, [])

    function createMarkup() {
        return {
            __html: `<div class="nyrcon">
	<div class="nyrt">
		<span>
			如何申请实验室认可扩项
		</span>
	</div>
	<div class="nxm_xq">
		<div class="nxcon">
		
			<p style="text-align: left;">
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(54, 96, 146);">
							认可政策要求：
						</span>
					</strong>
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);"></span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					根据CNAS-RL01:2019《实验室认可规则》的要求： <br/>
5.2.1 扩大认可范围<br/>
5.2.1.1 获准认可实验室在认可有效期内可以向 CNAS 秘书处提出扩大认可范围的申请。注：对于不能满足认可要求或违反认可规定而被暂停认可的实验室，在其恢复认可资格前，CNAS 不受理其扩大认可范围申请。<br/>
5.2.1.2 下列情形之一（但不限于）均属于扩大认可范围：a) 增加检测/校准/鉴定方法、依据标准/规范、检测/鉴定对象/校准仪器、项目/参数；注：增加等同采用的标准，按变更处理，不作为扩大认可范围。b) 增加检测/校准/鉴定地点；c) 扩大检测/校准/鉴定的测量范围/量程；d) 取消限制范围。<br/>
5.2.1.3 CNAS 秘书处根据情况可在监督评审、复评审时对申请扩大的认可范围进行评审，也可根据获准认可实验室需要，单独安排扩大认可范围的评审。当获准认可实验室需要在监督评审或复评审的同时扩大认可范围时，应至少在现场评审前 2 个月提出扩大认可范围的申请。<br/>
5.2.1.4 扩大认可范围的认可程序与初次认可相同，必须经过申请、评审、评定和批准。<br/>
5.2.1.5 扩大认可范围申请的受理与评审要求，与初次认可申请相同。<br/>
5.2.1.6 CNAS 秘书处原则上不允许评审组在现场评审时受理实验室提出的扩大认可范围的申请。<br/>
5.2.1.7 批准扩大认可范围的条件与初次认可相同，获准认可实验室在申请扩大认可的范围内必须具备相应技术能力，符合认可准则所规定的要求。<br/></span>
			</p>
		
			<p>
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-size: 16px; color: rgb(54, 96, 146); font-family: 宋体;"></span>
					</strong>
				</span>
			</p>
			<p>
				<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(0, 0, 0);"></span>
			</p>
			<p style="text-align: left;">
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(54, 96, 146);">
							扩项申请流程：
						</span>
					</strong>
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">一般扩项申请的流程与实验室认可初次申请基本一致，可参考如下流程图：</span>
			</p>
		<p style="text-align: center;">
<img width="50%" style="margin: auto;" src="`+liucheng+`" /></p>
<p>
		</div>
	</div>
</div>`
        };
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            {/*<div className="warp is_PC">*/}
            {/*    <div className="vub">*/}
            {/*        /!*<div className="vub_A">*!/*/}
            {/*        /!*    <ul>*!/*/}
            {/*        /!*        <li><a target="_blank">关于我们</a></li>*!/*/}
            {/*        /!*        <li><a target="_blank">联系我们</a></li>*!/*/}
            {/*        /!*    </ul>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <div className="vub_B">联系电话： 18957441030</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">

                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <img src={yemei}/>
            <div dangerouslySetInnerHTML={createMarkup()}/>
            <img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                <div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
