// App.js
import './App.css';
import routers from './router';    // 路由文件
import { Routes, Link, Route } from 'react-router-dom';    // 路由插件

function App() {
    return (
        // <div className="App App-header">
        //     {/*{*/}
        //     {/*    routers.map((item, index) => (*/}
        //     {/*        <Link to={item.path} key={index}> {item.name} </Link>*/}
        //     {/*    ))*/}
        //     {/*}*/}
        //     {/*<br />*/}
        //
        //
        // </div >
    <Routes>
        {
            routers.map((item, index) => (
                <Route path={item.path} key={index} element={<item.components />}></Route>
            ))
        }
    </Routes>
    );
}

export default App;